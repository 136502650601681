import ShopifyBuy from '@shopify/buy-button-js';

const useBuyButton = () => {

  const client = ShopifyBuy.buildClient({
    domain: 'shop.vestadesigns.co.uk',
    storefrontAccessToken: 'e52bd8f0ea6f19853e0f013cb8902440',
  });
  
  return ShopifyBuy.UI.init(client);

};

export default useBuyButton;