import { FC, useEffect, useRef } from 'react';
import useBuyButton from '../../hooks/useBuyButton';


import classes from './Product.module.css';
import './product.css';

export type ProductProps = {
  productId: number;
  withVariants?: boolean;
  tags?: string[];
}

const Product: FC<ProductProps> = (props: ProductProps) => {

  const product = useRef<HTMLDivElement>(null);
  const ui = useBuyButton();

  useEffect(() => {

    const productOpts = {
      text: {
        button: 'View product',
      },
      styles: {
        button: {
          'background': 'linear-gradient(7deg, rgba(255,255,0,1) -100%, rgba(253,0,255,1) 200%)',
          'font-family': 'Karla, sans-serif',
          'font-weight': 'bold',
        },
        title: {
          'font-family': 'Karla, sans-serif',
        },
        price: {
          'font-family': 'Karla, sans-serif',
        },
      },
      googleFonts: [
        'Karla',
      ],
    };
    
    const variantsImgContents = {
      img: !props.withVariants,
      imgWithCarousel: props.withVariants,
    };
    
    ui.createComponent('product', {
      id: props.productId,
      node: product.current,
      moneyFormat: '£{{amount}}',
      options: {
        product: {
          isButton: true,
          iframe: false,
          buttonDestination: 'modal',
          ...productOpts,
          styles: {
            ...productOpts.styles,
          },
          contents: {
            options: false,
          },
        },
        modalProduct: {
          ...productOpts,
          text: {
            button: 'Add to basket',
          },
          contents: {
            ...variantsImgContents,
          },
          styles: {
            button: {
              'font-family': '"Roboto Mono", sans-serif',
            }
          }
        },
        cart: {
          ...productOpts,
          note: false,
          popup: false,
          text: {
            button: 'Checkout',
            empty: 'Your basket is empty',
            currency: 'GBP',
            noteDescription: 'Special instructions',
            title: 'Basket'
          },
        },
        toggle: {
          styles: {
            toggle: {
              'background-image': 'linear-gradient(7deg, rgba(255,255,0,1) -50%, rgba(253,0,255,1) 150%)',
              'background-position': '50% 50%',
              'background-size': '200% 200%',
              'font-family': 'Karla, sans-serif',
              'font-weight': 'bold',
              'transition': 'background-position 500ms ease',
              ':hover': {
                'background-position': '50% 25%',
              },
            }
          },
          googleFonts: [
            'Karla',
          ],
          text: {
            title: 'Basket',
          },
        },
      },
    });
  }, []);

  return (
    <div ref={product} className={classes.Root}></div>
  );
};

export default Product;