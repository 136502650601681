import {  FC } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Navbar.module.css';

type NavbarProps = {
  logo: string;
  links: {
    to: string;
    text: string;
  }[];
};


const Navbar: FC<NavbarProps> = (props: NavbarProps) => {

  const links = props.links.map(link => {
    return (
      <NavLink 
        to={link.to} 
        end
        className={({ isActive }) => isActive ? [classes.Link, classes.LinkActive].join(' ') : classes.Link }
        key={link.to}
      >
        {link.text}
      </NavLink>
    );
  });

  return (
    <div className={classes.Root} role="navigation">
      <img className={classes.Logo} src={props.logo} />
      <div className={classes.Links}>{links}</div>
    </div>
  );
};

export default Navbar;