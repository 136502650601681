import { FC, useMemo } from 'react';
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';



import Shop from '../../routes/Shop';
import Navbar from '../Navbar';

import Logo from '../../images/logo-clipped-color-bg.png';

type AppProps = {

}

const App: FC<AppProps> = (props: AppProps) => {
  return (
    <Router>
      <Navbar links={[
          { to: '/', text: 'Home' },
          { to: '/shop', text: 'Shop' },
        ]}
        logo={Logo}
      />
      <Routes>
        <Route path="/" element={<div>Hello</div>} />
        <Route path="/shop" element={<Shop />} />
      </Routes>
    </Router>
  );
}

export default App;
