import { FC, PropsWithChildren } from 'react';

import classes from './ProductList.module.css';

type ProductListProps = PropsWithChildren<{}>


const ProductList: FC<ProductListProps> = (props: ProductListProps) => {

  return (
    <div className={classes.Root}>
      {props.children}
    </div>
  );
};

export default ProductList;