import { FC, useEffect, useMemo, useState } from 'react';
import CategoryPanel, { CategoryTag } from '../../components/CategoryPanel';
import Product from '../../components/Product';
import ProductList from '../../components/ProductList';

import classes from './Shop.module.css';

type ShopProps = {
}

const Shop: FC<ShopProps> = (props: ShopProps) => {

  const [tag, setTag] = useState<CategoryTag>('all');
  const [allProducts, setAllProducts] = useState<{
    tags: CategoryTag[];
    component: JSX.Element;
  }[]>([]);

  useEffect(() => {
    const ALL_PRODUCTS = [
      { tags: ['cushions'], product: { productId: 6900620853441, } },
      { tags: ['cushions'], product: { productId: 6900620853441, } },
      { tags: ['new_upholstered'], product: { productId: 6900617642177, } },
      { tags: ['new_upholstered'], product: { productId: 6900620853441, } },
      { tags: ['vintage_upholstered'], product: { productId: 6900617642177, } },
      { tags: ['vintage_upholstered'], product: { productId: 6900620853441, } },
      { tags: ['cushions'], product: { productId: 6900617642177, withVariants: true, }},
    ];

    const result = ALL_PRODUCTS.map((p, i) => {
      return {
        tags: p.tags as CategoryTag[],
        component: <Product key={`${p.product.productId}-${i}`} {...p.product} />,
      };
    });

    setAllProducts(result);
  }, []);

  const products = useMemo(() => {
    const result = allProducts
      .filter(p => tag === 'all' || p.tags.includes(tag))
      .map(p => p.component);

    return result;
  }, [tag, allProducts]);


  return (
    <div className={classes.Root}>
      <div>
        <CategoryPanel onChangeCategory={setTag} />
      </div>
      <ProductList>{products}</ProductList>
    </div>
  )
};

export default Shop;