import { FC } from 'react';

import classes from './CategoryPanel.module.css';

export type CategoryTag = 'all' | 'cushions' | 'new_upholstered' | 'vintage_upholstered';

type Category = {
  tag: CategoryTag;
  display: string;
};

const CATEGORIES: Category[] = [
  { tag: 'all', display: 'All' },
  { tag: 'cushions', display: 'Cushions' },
  { tag: 'new_upholstered', display: 'New Upholstered' },
  { tag: 'vintage_upholstered', display: 'Vintage Reupholstered' },
];

export type CategoryPanelProps = {
  onChangeCategory?: (category: CategoryTag) => void;
};

const CategoryPanel: FC<CategoryPanelProps> = (props: CategoryPanelProps) => {
  return (
    <div className={classes.Root}>
      <h3 className={`${classes.Row} ${classes.Title}`}>Categories</h3>
      { CATEGORIES.map(c => {
        return (
          <button 
            className={`${classes.Row} ${classes.Button}`}
            key={c.display}
            onClick={() => props.onChangeCategory && props.onChangeCategory(c.tag)}
          >{c.display}</button>
        );
      })}
    </div>
  );
}

export default CategoryPanel